<template>
  <div style="height: 100%;width:100%;">
    <div id="mapDiv" style="height: 100%;border-radius: 9px"></div>
  </div>
</template>

<script>
export default {
  name: "MapComponent",
  data() {
    return {
      map: null, // 用于存储地图实例
    };
  },
  created() {


  },
  mounted() {
    setTimeout(()=>{

      this.initMap()

    },1000)
  },

  methods: {
    initMap() {
      var T = window.T;
      console.log("---------------------",T.Map)
      if (T && T.Map) {
        console.log('天地图API');
        var map = new T.Map('mapDiv');
        map.centerAndZoom(new T.LngLat(31.358891,30.06756), 10);
        // 添加标记
        // var icon = new T.Icon({
        //   url: 'https://cn.ogdri.com/static/img/groundHome4.210434ea.png', // 指定图标的URL
        //   size: new T.Point(12, 12), // 指定图标的大小
        // });
        // var marker = new T.Marker(new T.LngLat(31.358891,30.06756), { icon: icon }); // 创建标记并指定图标
        // map.addOverLay(marker); // 将标记添加到地图上
        var marker = new T.Marker(new T.LngLat(31.2357, 30.0444)); // 创建标记
        map.addOverLay(marker); // 将标记添加到地图上
        this.map = map;
      } else {
        console.error('天地图API未加载或不支持当前浏览器');
      }
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
#mapDiv {
  height: 100%;
}
</style>
